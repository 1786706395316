import './home.css';
import { AxiosError } from 'axios';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import { Card } from '../../rxlib/components/card';
import { useAppSelector } from '../../store/hooks';
import Spinner from '../../rxlib/components/spinner';
import { usuarioTemPermissao } from '../../rxlib/services/seguranca';
import { RxlibLayout } from '../../rxlib/components/layout/rxlib-Layout';
import { AcessoProps, ApiError, PromisseMap } from '../../services/tipos';
import { ModalWarning } from '../../rxlib/components/modal/modal-warning';
import { formatarData, tratarErroApi } from '../../rxlib/services/utilitarios';

function Home(props: AcessoProps) {
    const [acao, setAcao] = useState(props.match.params.acao);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [token] = useState<string>(useAppSelector(state => state.token));
    const [quantidadeUsuarios, setQuantidadeUsuarios] = useState<string>('');
    const [quantidadeProdutos, setQuantidadeProdutos] = useState<string>('');
    const [quantidadeLicenciadas, setQuantidadeLicenciadas] = useState<string>('');
    const [quantidadeLicencasDesktop, setQuantidadeLicencasDesktop] = useState<string>('');
    const [dataPadraoExpiracaoLicenca, setDataPadraoExpiracaoLicenca] = useState<string>('');

    const handleHide = () => setShowWarning(false);

    const temPermissaoQuantidadeLicencaWeb = usuarioTemPermissao(token, 'licenciada_visualizar') &&
        usuarioTemPermissao(token, 'versao_atual_produto_visualizar') &&
        usuarioTemPermissao(token, 'licenca_desktop_visualizar');

    const temPermissaoQuantidadeLicencaDesktop = usuarioTemPermissao(token, 'licenca_desktop_visualizar') &&
        usuarioTemPermissao(token, 'licenca_desktop_consultar_pelos_produtos');

    const temPermissaoQuantidadeUsuariosWeb = usuarioTemPermissao(token, 'usuario_visualizar') &&
        usuarioTemPermissao(token, 'versao_atual_produto_visualizar') &&
        usuarioTemPermissao(token, 'licenca_desktop_visualizar');

    const temPermissaoDataPadraoExpiracaoLicenca = usuarioTemPermissao(token, 'licenca_desktop_visualizar') &&
        usuarioTemPermissao(token, 'versao_atual_produto_visualizar');

    const temPermissaoQuantidadeProdutos = usuarioTemPermissao(token, 'produto_visualizar') &&
        usuarioTemPermissao(token, 'versao_atual_produto_visualizar') &&
        usuarioTemPermissao(token, 'licenca_desktop_visualizar');

    const ChavesPromises = {
        quantidadeLicenciadas: 'quantidadeLicenciadas',
        quantidadeLicencasDesktop: 'quantidadeLicencasDesktop',
        quantidadeUsuarios: 'quantidadeUsuarios',
        dataPadraoExpiracaoLicenca: 'dataPadraoExpiracaoLicenca',
        quantidadeProdutos: 'quantidadeProdutos',
    }

    function showMensagemSemAcesso() {
        setMessageWarning(['Usuário logado não tem permissão para realizar essa ação.']);
        setShowWarning(true);
        setAcao('');
    }

    useEffect(() => {
        setCarregando(true);
        setTimeout(() => {
            const promisesMap: PromisseMap[] = [];

            if (temPermissaoQuantidadeLicencaWeb) {
                promisesMap.push({ chave: ChavesPromises.quantidadeLicenciadas, promise: api.get('/OData/Licenciada?$count=true') });
            }

            if (temPermissaoQuantidadeLicencaDesktop) {
                promisesMap.push({ chave: ChavesPromises.quantidadeLicencasDesktop, promise: api.get('/OData/LicencaDesktop?completa=true&$count=true') });
            }

            if (temPermissaoQuantidadeUsuariosWeb) {
                promisesMap.push({ chave: ChavesPromises.quantidadeUsuarios, promise: api.get('/OData/Usuario?$count=true') });
            }

            if (temPermissaoDataPadraoExpiracaoLicenca) {
                promisesMap.push({ chave: ChavesPromises.dataPadraoExpiracaoLicenca, promise: api.get('/DataPadraoExpiracaoLicenca') });
            }

            if (temPermissaoQuantidadeProdutos) {
                promisesMap.push({ chave: ChavesPromises.quantidadeProdutos, promise: api.get('/OData/Produto?$count=true') });
            }

            const promises = promisesMap.map(p => p.promise);

            Promise.all(promises)
                .then(response => {
                    response.forEach((res, index) => {
                        const chave = promisesMap[index].chave;
                        switch (chave) {
                            case ChavesPromises.quantidadeLicenciadas:
                                setQuantidadeLicenciadas(res.data['@odata.count']);
                                break;
                            case ChavesPromises.quantidadeLicencasDesktop:
                                setQuantidadeLicencasDesktop(res.data['@odata.count']);
                                break;
                            case ChavesPromises.quantidadeUsuarios:
                                setQuantidadeUsuarios(res.data['@odata.count']);
                                break;
                            case ChavesPromises.dataPadraoExpiracaoLicenca:
                                setDataPadraoExpiracaoLicenca(res.data[0].data);
                                break;
                            case ChavesPromises.quantidadeProdutos:
                                setQuantidadeProdutos(res.data['@odata.count']);
                                break;
                        }
                    });
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, 'Não foi possível realizar a consulta: '));
                    setShowWarning(true);
                });
        }, 250);
    }, [temPermissaoQuantidadeLicencaWeb, temPermissaoQuantidadeLicencaDesktop,
        temPermissaoQuantidadeUsuariosWeb, temPermissaoDataPadraoExpiracaoLicenca,
        temPermissaoQuantidadeProdutos, ChavesPromises.quantidadeLicenciadas,
        ChavesPromises.quantidadeLicencasDesktop, ChavesPromises.quantidadeUsuarios,
        ChavesPromises.dataPadraoExpiracaoLicenca, ChavesPromises.quantidadeProdutos
    ]);

    return (
        <>
            {
                acao === 'acessobloqueado'
                    ? showMensagemSemAcesso()
                    : ''
            }
            <RxlibLayout>
                {
                    carregando
                        ? <Spinner
                            classStyle='rxlib-spinner'
                            classNameSpinner='rxlib-home-spinner' />
                        : <div className='rxlib-home-menu'>
                            <div className='rxlib-home-cards'>
                                {temPermissaoQuantidadeLicencaWeb &&
                                    <div>
                                        <Card
                                            subtitulo=''
                                            texto={quantidadeLicenciadas}
                                            classNameIcone='fas fa-user-tie'
                                            titulo='Quantidade de licenciadas web' />
                                    </div>}
                                {temPermissaoQuantidadeLicencaDesktop &&
                                    <div>
                                        <Card
                                            subtitulo=''
                                            classNameIcone='fas fa-desktop'
                                            texto={quantidadeLicencasDesktop}
                                            titulo='Quantidade de licenças desktop' />
                                    </div>}
                            </div>
                            <div className='rxlib-home-cards mt-3'>
                                {temPermissaoQuantidadeUsuariosWeb &&
                                    <div>
                                        <Card
                                            subtitulo=''
                                            texto={quantidadeUsuarios}
                                            classNameIcone='fas fa-user'
                                            titulo='Quantidade de usuários web' />
                                    </div>}
                                {temPermissaoDataPadraoExpiracaoLicenca &&
                                    <div className='rxlib-home-data-padrao-expiracao-licenca'>
                                        <Card
                                            subtitulo=''
                                            classNameIcone='fas fa-calendar'
                                            titulo='Data padrão expiração licença'
                                            texto={formatarData(dataPadraoExpiracaoLicenca)} />
                                    </div>}
                            </div>
                            <div className='rxlib-home-cards mt-3'>
                                {temPermissaoQuantidadeProdutos &&
                                    <div>
                                        <Card
                                            subtitulo=''
                                            texto={quantidadeProdutos}
                                            classNameIcone='fas fa-box'
                                            titulo='Quantidade de produtos' />
                                    </div>}
                            </div>
                        </div>
                }
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
            </RxlibLayout>
        </>
    );
}

export default Home;