export function obterVersao(): string {
    return 'v11.1.0';
}

export function obterNomeEmpresa(): string {
    return 'Caronte';
}

export function obterIdProduto(): string {
    return '75c817d7-a860-4486-a710-61d54b2cacfb';
}

export function formatarPorTipoPersonalizado(type: string, value: string): string {
    if (type) {
        switch (type) {
            default:
                return value;
        }
    } else {
        return value;
    }
}

export function formatarTamanhoDoBanco(valorEmKB: number): string {
    const fatorDeConversao = 1024;

    if (valorEmKB < fatorDeConversao) {
        return `${valorEmKB} KB`;
    }

    const valorEmMB = valorEmKB / fatorDeConversao;

    if (valorEmMB < fatorDeConversao) {
        return `${valorEmMB.toFixed(2)} MB`;
    }

    const valorEmGB = valorEmMB / fatorDeConversao;
    return `${valorEmGB.toFixed(2)} GB`;
}
